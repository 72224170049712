module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Future Artists","short_name":"Future Artists","start_url":"/","background_color":"#332d70","theme_color":"#332d70","display":"minimal-ui","icon":"src/images/futureartists-icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a359829d0bc7de584fe5e19726a96888"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10746686-1","anonymize":true,"cookieDomain":"futureartists.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
